.global-tooltip{
  font-family: var(--content-font);
  font-weight: 400;
  font-size: 14px;
  background-color: #252C32;
  color: #fff;
  width: fit-content;
  padding: 5px;
  border-radius: 3px;
  position: absolute;
  z-index: 1;
  display: none;
  text-align: center;
}
.container {
  color: #201E1F;
  display: flex;
  justify-content: center;
}

.content {
  width: 1000px;
  margin-top: 20px;
  // margin: 20px auto 0px auto;
}

.title>h1 {
  font-family: var(--sub-title-font) !important;
  font-weight: 500;
  font-size: 30px;
  margin-bottom: 20px;
}

.two-column-layout {
  display: flex;
}

.column-one .upload-box {
  background-color: #fff;
  padding: 10px 20px;
  border-radius: 5px;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  width: 320px;
  cursor: pointer;
}

.upload-box img {
  width: auto;
  height: 35px;
}

.upload-box p {
  font-size: 20px;
  font-family: var(--content-font) !important;
  font-weight: 400;
  margin-left: 15px;
}

.column-two {
  margin-top: 40px;
  margin-left: 20px;
}

.column-two h3 {
  font-family: var(--sub-title-font) !important;
  font-weight: 500;
  font-size: 30px;
  margin-bottom: 20px;
}


.docs-list-box {
  width: 420px;
  overflow-y: auto;
  padding: 10px 15px;
  min-height: 200px;
  max-height: 500px;
}

.docs-item {
  box-shadow: var(--global-box-shadow);
  background-color: #fff;
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-radius: 5px;
  padding: 15px;
  margin-bottom: 20px;
  position: relative;
}
.docs-item > p:hover .global-tooltip{
  display: inline;
  bottom: 60px;
  left: 50%;
  transform: translate(-50%, 0%);
  width: 350px;
  word-break: break-all;
}

.docs-item>img {
  margin-left: auto;
  width: auto;
  height: 25px;
  cursor: pointer;
}

.docs-item>p {
  font-size: 16px;
  font-family: var(--content-font) !important;
  font-weight: 400;
  padding-right: 15px;
  word-break: break-all;

}

.file-limit-info {
  display: block;
  margin-left: 15px;
  margin-bottom: 20px;
}

.file-limit-info h4 {
  font-family: var(--sub-title-font) !important;
  font-weight: 500;
  font-size: 20px;
  margin-bottom: 10px;
}

.file-limit-info div {
  display: flex;
  align-items: center;
}

.file-limit-info div img {
  width: auto;
  height: 8px;
}

.file-limit-info div p {
  font-size: 16px;
  font-family: var(--content-font) !important;
  font-weight: 400;
  margin-left: 8px;
}

.file-limit-info a {
  color: #f18f00;
  text-decoration: underline;
  font-size: 18px;
  font-family: var(--content-font) !important;
  font-weight: 400;
}

.file-limit-info a:hover {
  color: #f18f00;
}

// file upload progress
.upload-file-container {
  width: 400px;
  margin-bottom: 20px;
}

.upload-file-content {
  box-shadow: var(--global-box-shadow);
  border-radius: 10px;
  background-color: #fff;
  display: flex;
  align-items: center;
  // justify-content: space-between;
  padding: 10px 10px;
  border-bottom-left-radius: 0px;
  border-bottom-right-radius: 0px;
  padding-bottom: 0px;
}

.upload-file-loading {
  min-width: 60px;
}

.upload-file-loading img {
  height: 35px;
  width: auto;
}

.upload-file-loading p {
  color: #f18f01;
  font-family: var(--content-font);
  font-weight: 400;
  font-size: 14px;
}

.upload-file-title {
  overflow-wrap: anywhere;
  font-family: var(--content-font);
  font-weight: 400;
  font-size: 18px;
  line-height: 21px;
}

.progress-bar {
  height: 20px;
  width: 100%;
  position: relative;
}

.progress-bar-white {
  background-color: #fff;
  height: 10px;
  width: 100%;
  border-bottom-left-radius: 10px;
  border-bottom-right-radius: 10px;
  position: absolute;
  top: 0px;
  left: 0px;
}

.progress-bar-inner {
  transition: width 1s;
  height: 23px;
  background-color: #f18f01;
  border-bottom-left-radius: 5px;
  border-bottom-right-radius: 5px;
}

.copyright-text {
  margin-top: 30px;
  padding-bottom: 40px;
  font-size: 14px;
  font-family: var(--content-font) !important;
  font-weight: 400;
  color: #000;
}

//Exam Space Menu
.exam-space-menu {
  font-size: 20px;
  color: #27345E;
  display: flex;
  align-items: center;
  gap: 10px;
  width: 100%;
}

.exam-space-topic,
.exam-space-add-new-file,
.exam-space-import {
  box-shadow: var(--global-box-shadow);
  display: flex;
  gap: 10px;
  min-width: 200px;
  align-items: center;
  background-color: white;
  padding: 4px 10px;
  border-radius: 10px;
  cursor: pointer;
}

.exam-space-topic {
  justify-content: space-between;
}

.exam-space-topic .max-icon {
  fill: #27345E;
  font-size: 16px;
}


.add-icon,
.import-icon {
  fill: white;
  font-size: 18px;
  padding: 3px;
  background-color: #F18F01;
  height: 25px;
  width: 25px;
  border-radius: 50%;
}

.doc-loading-icon{
  display: none;
}
.trash-icon-container{
  display: flex;
  align-items: center;
  justify-content: center;
  width: 20px;
}
.trash-icon{
  width: 17px;
  height: auto;
  cursor: pointer;
}
.trash-icon:hover path{
  fill: #000;
}
.doc-loading-icon{
  height: 22px;
  width: auto;
}

.exam-space-add-file-container {
  display: none;
}


@media only screen and (max-width: 1450px) and (min-width: 1000px) {
  .container {
    margin-left: 220px;
    justify-content: flex-start;
  }

  .content {
    width: 70vw;
  }
}

@media only screen and (max-width: 1000px) and (min-width: 800px) {
  .container {
    margin-left: 0px;
    justify-content: center;
  }

  .content {
    width: 85vw;
  }

  .two-column-layout {
    justify-content: space-between;
  }

  .column-two {

    margin-left: 45px;
  }

  .docs-list-box {
    width: 420px;
  }
}

@media screen and (max-width: 800px) {
  .content {
    width: 90vw;
  }

  .two-column-layout {
    flex-direction: column;
  }

  .column-one .upload-box {
    width: 100%;
  }

  .column-two {
    margin-left: 0px;
    margin-top: 20px;
  }

  .column-two h3 {
    display: none;
  }

  .docs-list-box {
    border-radius: 5px;
    padding: 20px;
    width: 100%;
    height: 500px;
  }

  .copyright-text {
    text-align: center;
    padding-bottom: 20px;
  }

  .upload-file-container {
    width: 100%;
  }

  .upload-file-title {
    font-size: 16px;
  }

  .upload-file-loading img {
    height: 30px;
  }

  .upload-file-loading p {
    font-size: 13px;
  }

  .title>h1 {
    font-weight: 500;
    font-size: 25px;
  }


  .docs-list-box {
    padding: 5px 10px;
  }

  .exam-space-text {
    font-size: 18px;
  }

  .exam-space-topic p {
    font-size: 18px;
  }


  .exam-space-topic {
    min-width: fit-content;
  }

  .exam-space-add-new-file,
  .exam-space-import {
    display: none;
  }

  .exam-space-add-file {
    display: flex;
    gap: 10px;
    font-size: 18px;
    // min-width: 200px;
    align-items: center;
    background-color: white;
    padding: 4px 10px;
    border-radius: 10px;
    cursor: pointer;
  }

  .add-icon,
  .import-icon {
    font-size: 18px;
    height: 20px;
    width: 20px;
  }

  .exam-space-add-file-container {
    position: relative;
    cursor: pointer;
  }

  .exam-space-add-file-drop-menu {
    position: absolute;
    background-color: white;
    width: 120px;
    border-radius: 5px;
    z-index: 3;
    right: 0px;
  }

  .new-file-drop-menu,
  .upload-file-drop-menu {
    display: flex;
    gap: 5px;
    font-size: 12px;
    align-items: center;
    padding: 5px;
    // fill: #f18f00;
  }

  .new-file-drop-menu {
    border-bottom: 1px solid rgb(211, 211, 211);
  }

  .add-drop-icon {
    fill: #f18f00;
  }

  .exam-space-add-file-container {
    display: block;
  }

  .title>h1 {
    margin-bottom: 0px;
  }
}

@media screen and (max-width: 500px) {
  .exam-space-text {
    font-size: 15px;
  }

  .exam-space-topic p {
    font-size: 15px;
  }

  .exam-space-add-file-mobile {
    font-size: 15px;
  }

  .add-icon {
    font-size: 15px;
    height: 16px;
    width: 16px;
  }

  .add-icon:hover {
    background-color: #f2f3f6
  }

  .title>h1 {
    font-size: 20px;
  }

  .docs-item>p {
    font-size: 15px;
  }

  .exam-space-topic,
  .exam-space-add-file {
    border-radius: 5px;
  }

}


@media screen and (max-width:400px) {
  .exam-space-text {
    font-size: 14px;
  }

  .exam-space-topic p {
    font-size: 12px;
  }

  .exam-space-add-file-mobile {
    font-size: 12px;
  }

  .add-icon {
    font-size: 14px;
    height: 16px;
    width: 16px;
  }

  .add-icon:hover {
    background-color: #f2f3f6
  }

  .title>h1 {
    font-size: 20px;
  }

  .docs-item>p {
    font-size: 12px;
  }

  .exam-space-topic,
  .exam-space-add-file {
    border-radius: 5px;
  }

}

.custom-toast-message {
  display: flex;
  flex-direction: column;
  position: relative;
  
  .tooltip-wrapper {
    position: relative;
    display: inline-block;
    
    .tooltip-trigger {
      text-decoration: underline;
      cursor: pointer;
      color: rgb(0, 130, 252);
      
      &:hover + .tooltip-content {
        visibility: visible;
        opacity: 1;
      }
    }
    
    .tooltip-content {
      visibility: hidden;
      opacity: 0;
      position: absolute;
      top: 100%;
      left: 0;
      background-color: #333;
      color: #fff;
      padding: 8px 12px;
      border-radius: 4px;
      width: 300px;
      transition: opacity 0.3s;
      z-index: 99999;
      font-weight: 400;
      transform: translateY(5px);
      pointer-events: none;
      
      // &:after {
      //   content: "";
      //   position: absolute;
      //   bottom: 100%;
      //   left: 15px;
      //   border-width: 5px;
      //   border-style: solid;
      //   border-color: transparent transparent #333 transparent;
      // }
    }
  }
}

:global(.Toastify__toast-container) {
  overflow: visible !important;
  z-index: 99999 !important;
}

:global(.Toastify__toast) {
  overflow: visible !important;
  z-index: 99999 !important;
}

:global(.Toastify__toast-body) {
  overflow: visible !important;
}