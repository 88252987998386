:root{
  --quiz-top-bar-z-index: 1
}
.mcq-new-container {
  display: flex;
  justify-content: center;
  // font-family: "product-sans-regular-400", sans-serif;
}
.mcq-new-wrapper {
  width: 800px;
  margin-top: 70px;
  margin-bottom: 100px;
}
.mcq-new-question-heading {
  font-size: 27px;
  font-weight: 600;
  color: #201e1f;
  text-align: center;
  position: relative;
  font-family: var(--sub-title-font) !important;
  font-weight: 500;
  letter-spacing: -0.5px;
}
.mcq-new-options-container {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  width: 550px;
  margin-left: auto;
  margin-right: auto;
  margin-top: 40px;
  gap: 25px;
}
.mcq-new-option-card {
  background-color: #fff;
  font-size: 18px;
  font-weight: 400;
  min-height: 170px;
  // margin-bottom: 20px;
  padding: 15px;
  border-radius: 5px;
  width: 45%;
  cursor: pointer;
  margin-top: 0px;
  display: flex;
  align-items: flex-start;
  column-gap: 10px;
  flex-grow: 1;
  box-shadow: var(--global-box-shadow);
}
.quiz-options-sequence{
  width: 30px;
  height: 30px;
  border-radius: 50%;
  background-color: var(--light-blue);
  display: flex;
  justify-content: center;
  align-items: center;
  // outline: 1px solid #000;
  flex-shrink: 0;
}
.quiz-options-sequence span{
  font-size: 18px;
  font-family: var(--content-font) !important;
  font-weight: 400;
  color: #000;
  line-height: 10px;
}
.quiz-options-text{
  flex-grow: 1;
  font-size: 16px;
  font-family: var(--content-font) !important;
  font-weight: 400;
  line-height: 24px;
  color: #000;
}
// quiz tips popup box
.mcq-progress-bar-container {
  margin-left: auto;
  margin-right: auto;
  // visibility: visible;
  visibility: hidden;
  position: fixed;
  width: 500px;
  // margin-top: 40px;
  left: 0px;
  right: 0px;
  bottom: 10px;
  transform: translateY(70%);
  transition: all 1s ease;
  z-index: 10;
}
.mcq-progress-bar {
  margin-top: -10px;
  // background-color: #c1ff72;
  height: 10px;
  width: 100%;
  border-top-right-radius: 5px;
  border-top-left-radius: 5px;
  transition: width 3s;
}
.mcq-progress-bar-content {
  text-align: center;
  position: relative;
  min-height: 150px;
  box-shadow: var(--global-box-shadow);
  background-color: #fff;
  border-bottom-right-radius: 5px;
  border-bottom-left-radius: 5px;
  // border-radius: 10px;
  // padding-bottom: 60px;
}
.mcq-progress-bar-content h3 {
  padding-top: 15px;
  font-size: 22px;
  font-family: var(--sub-title-font) !important;
  font-weight: 500;
}
.mcq-progress-bar-content p {
  font-family: var(--content-font) !important;
  font-size: 15px;
  font-weight: 400;
  margin-top: 10px;
  min-height: 50px;
  padding: 0px 15px 0px 15px;
}
.mcq-new-question-feedback-icon {
  background-color: #fff;
  border-radius: 30px;
  position: absolute;
  right: -5%;
  top: 12px;
  height: 35px;
  width: 35px;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
}

.vl {
  border-left: 1px solid #d8d8d8;
  height: 30px;
}

.mcq-new-question-feedback-icon img {
  width: auto;
  height: 20px;
}
.mcq-new-tips-feedback-icon {
  background-color: #fff;
  border-radius: 7px;
  position: absolute;
  right: -50px;
  top: 10px;
  height: 30px;
  width: 30px;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
}

.mcq-new-tips-feedback-icon img {
  width: auto;
  height: 16px;
}

@keyframes slide {
  from {
    width: 100%;
  }
  to {
    width: 0%;
  }
}
.quiz-hints-hover-tips-container {
  position: absolute;
  top: -11px;
  left: -111px;
}
.quiz-hints-hover-tips-content {
  display: flex;
  align-items: center;
}
.quiz-hints-hover-tips-content p {
  font-size: 16px;
  width: 110px;
  color: #000;
  margin-top: 0px;
  display: block;
}
.quiz-hints-hover-tips-content img {
  height: 43px;
  width: auto;
  transform: rotate(40deg);
}
.feedback-menu-container {
  display: flex;
  justify-content: center;
  column-gap: 20px;  
  align-items: center;
  height: 80px;
}

// .feedback-btn-box{
//   border-right: 1px solid #d8d8d8;
// }

.quiz-feedback-btn{
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  border: 1px solid var(--stripe-dark-gray);
  border-radius: 5px;
  height: 40px;
  width: 190px;
  column-gap: 5px;
}
.quiz-feedback-btn span{
  font-family: var(--sub-title-font) !important;
  font-size: 18px;
  font-weight: 500;
  color: #000;
}
.quiz-feedback-btn img{
  height: 17px;
  width: auto;
}
.quiz-feedback-btn:hover{
  background-color: var(--pearl-white);
}

// .feedback-btn-box:hover ,.feedback-see-btn-box:hover{
//   background-color: #F7F7F7;
// }

.feedback-see-ans-text,
.feedback-see-pdf {
  font-family: var(--sub-title-font) !important;
  font-size: 18px;
  letter-spacing: 0px;
  cursor: pointer;
  font-weight: 500;
  // position: fixed;
  bottom: 20px;
  left: 0px;
  right: 0px;
  color: #27345e;
}
.feedback-see-ans-content {
  min-height: 120px !important;
  // padding-bottom: 50px;
}
.feedback-see-ans-content-title{
  display: flex;
  justify-content: center;
  align-items: flex-start;
  margin-bottom: 10px;
  padding-top: 15px;
  column-gap: 10px;
}
.feedback-see-ans-content-title span{
  width: 25px;
  height: 25px;
  border-radius: 50%;
  background-color: var(--light-blue);
  display: flex;
  justify-content: center;
  align-items: center;
  flex-shrink: 0;
  font-size: 18px;
  font-family: var(--content-font) !important;
  font-weight: 400;
  color: #000;
  line-height: 10px;
  box-shadow: var(--global-box-shadow);
}
.feedback-see-ans-content-title h5{
  font-family: var(--sub-title-font) !important;
  font-weight: 500;
  font-size: 20px !important;
  letter-spacing: 0px;
  color: #000;
  width: fit-content;
  text-align: center;
}
// .feedback-see-ans-content > h5 {
//   font-family: var(--title-font) !important;
//   font-weight: 600;
//   font-size: 20px !important;
//   letter-spacing: 0px;
//   margin-bottom: 10px;
//   padding-top: 15px;
//   color: #27345e;
// }
.feedback-see-ans-content > h6 {
  font-family: var(--content-font) !important;
  font-weight: 400;
  font-size: 16px !important;
  letter-spacing: 0px;
  // margin-bottom: 10px;
}
// nextArrowIcon
.nextQuizClick {
  cursor: pointer;
  position: absolute;
  z-index: 10;
  right: 0;
  // top: -30px;

  top: 50%;
  left: 100%;
  transform: translate(-100%, -50%);

  background-color: #fff;
  padding: 90px 50px;
  // left: 50%;
  // transform: translatey(-100%);
  border-radius: 15px 0px 0px 15px;

  .arrorIcon {
    padding: 2px 1px;
    border-radius: 100%;
    transform: rotate(90deg);
    font-size: 35px;
    color: gray;
    margin-top: -30px;
  }
}

.nextQuizClickMob {
  display: none;
}

.pdf-container-quiz {
  z-index: 13;
  background-color: rgb(214, 212, 212);
  visibility: hidden;
  padding: 0px;
  position: fixed;
  top: 0px;
  right: 0px;
  width: 40vw;
  height: 100vh;
  transition: transform 1s ease; /* our nice transition */
  transform: translateX(100%);
}

.pdf_tools_container,
.pdf_tools_container-menu {
  display: flex;
  gap: 30px;
  justify-content: space-between;
}

.pdf_tools_container_close {
  cursor: pointer;
  display: flex;
  gap: 3px;
  align-items: center;
  padding: 10px 0px 0px 10px;
}

.pdf_tools_container_close p {
  font-size: 20px;
}

.pdf_tools_container {
  padding: 20px 45px;
}

.tabMenu {
  cursor: pointer;
  position: absolute;
  z-index: 10;
  right: 200px;
  bottom: 20px;
  // top: -30px;
  background-color: #fff;
  padding: 20px 50px;
  // left: 50%;
  border-radius: 15px;
  .text {
    padding: 2px 1px;
    border-radius: 100%;
    font-size: 16px;
    color: black;
  }
}

.quiz-empty-state-container {
  display: flex;
  justify-content: center;
  width: 100%;
  margin-top: 100px;
}
.quiz-empty-state-content {
  box-shadow: var(--global-box-shadow);
  background-color: #fff;
  padding: 20px;
  width: 500px;
  min-height: 250px;
  border-radius: 20px;
}
.quiz-empty-state-upload-file {
  text-align: center;
}
.quiz-empty-state-upload-file > p {
  font-size: 22px;
  font-weight: 400;
  margin-top: 25px;
}
// .quiz-empty-state-upload-file > button{
//   font-size: 22px;
//   font-weight: 600;
// }
.quiz-empty-state-upload-file > button {
  margin-top: 35px;
  background-color: #f18f01;
  padding: 10px 20px;
  font-size: 22px;
  font-weight: 600;
  color: #fff;
  border-radius: 20px;
}
.quiz-empty-state-upload-file > button > img {
  width: auto;
  height: 25px;
  margin-right: 15px;
}
.quiz-empty-state-generate {
  text-align: center;
}
.quiz-empty-state-generate > img {
  width: auto;
  height: 60px;
  margin-top: 20px;
}
.quiz-empty-state-generate > p {
  font-size: 24px;
  font-weight: 400;
  margin-top: 20px;
}

.icon-left {
  width: 22px;
}

.back-to-question {
  font-size: 20px;
  // padding-top: 5px;
  // padding-left: 5px;
}

@media only screen and (max-width: 1000px) {
  .mcq-new-wrapper {
    width: 85vw;
    margin-top: 100px;
  }
  .mcq-new-option-card {
    font-family: var(--content-font) !important;
    font-weight: 400;
    font-size: 18px;
    margin-bottom: 10px;
    padding: 20px;
    width: 100%;
    min-height: 100px;
  }
  .mcq-new-options-container {
    justify-content: center;
    flex-direction: column;
    width: 80%;
    gap: 5px;
  }
  .mcq-new-option-card {
    width: 100%;
  }
  .nextQuizClick,
  .tabMenu {
    display: none;
  }
}
@media screen and (max-width: 600px) {
  .mcq-progress-bar-container {
    width: 95%;
    position: fixed;
    bottom: 0px;
    left: 0;
    right: 0;
    transform: translateY(0%);
  }
}
@media screen and (max-width: 500px) {
  // .quiz-feedback-btn{
  //   width: 130px;
  // }
  // .quiz-feedback-btn span {
  //   font-size: 14px;
  // }
  // .quiz-feedback-btn img {
  //   height: 16px;
  // }
  .quiz-feedback-btn {
    width: 155px;
  }
  .quiz-feedback-btn span{
    font-size: 15px;
  }
  .feedback-menu-container{
    column-gap: 8px;
  }
  .feedback-menu-container {
    // padding: 0px;
    // padding: 10px 15px;
  }
  .mcq-new-question-feedback-icon {
    right: 0px;
    top: -40px;
  }
  .mcq-new-question-heading {
    font-size: 22px;
  }
  .mcq-progress-bar-content {
    -webkit-user-select: none; /* Safari */
    -moz-user-select: none; /* Firefox */
    -ms-user-select: none; /* IE10+/Edge */
    user-select: none; /* Standard */
    padding-bottom: 50;
  }
  .mcq-new-options-container {
    flex-direction: column;
    width: 100%;
    align-items: center;
    margin-top: 30px;
    gap: 5px;
  }
  .mcq-new-option-card {
    font-family: var(--content-font) !important;
    font-weight: 400;
    font-size: 18px;
    margin-bottom: 10px;
    padding: 20px;
    width: 100%;
    min-height: 100px;
  }
  .mcq-new-tips-feedback-icon {
    right: -33px;
    top: 0px;
  }
  .mcq-progress-bar-content p {
    font-size: 18px;
  }
  .mcq-progress-bar-content h3 {
    font-family: var(--sub-title-font) !important;
    font-size: 24px;
    font-weight: 500;
    letter-spacing: 0px;
  }
  .quiz-hints-hover-tips-content p {
    display: none;
  }
  .quiz-hints-hover-tips-container {
    top: -11px;
    left: -11px;
  }
  .nextQuizClick,
  .tabMenu {
    display: none;
  }

  .quiz-empty-state-content {
    width: 95vw;
  }

  .pdf-container-quiz {
    width: 100vw;
  }

  .pdf_tools_container,
  .pdf_tools_container-menu {
    gap: 5px;
  }

  .pdf_tools_container-menu {

    padding: 15px 30px 0px 30px;
    justify-content: center;
  }

  .pdf_tools_container {
    display: block;
  }

  .pdf_tools_container {
    padding: 0px 0px;
  }

  .pdf_tools_container_close p {
    font-size: 20px;
  }

  .nextQuizClickMob {
    display: block;
    cursor: pointer;
    position: absolute;
    right: 0;
    // top: -30px;
    // background-color: #fff;
    padding: 10px 1px;
    // left: 50%;
    transform: translatey(-900%);
    border-radius: 15px 0px 0px 15px;

    .arrorIcon > img {
      width: auto;
      height: 35px;
      // padding: 2px 1px;
      // border-radius: 100%;
      // transform: rotate(90deg);
      // font-size: 35px;
      color: gray;
    }
  }
}
