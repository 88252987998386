.global-tooltip{
    font-family: var(--content-font);
    font-weight: 400;
    font-size: 14px;
    background-color: #252C32;
    color: #fff;
    width: fit-content;
    padding: 5px;
    border-radius: 3px;
    position: absolute;
    z-index: 1;
    display: none;
    text-align: center;
}
.quiz-top-bar-container{
    position: fixed;
    top: 20px;
    right: 20px;
    z-index: 11;
}
.quiz-top-bar-content{
    position: relative;
    display: flex;
    align-items: center;
    column-gap: 10px;
}
// filters
.quiz-filter-container{
    position: relative;
}
.quiz-filter-backdrop{
    position: fixed;
    z-index: 9;
    background-color: var(--pearl-white);
    width: 100vw;
    height: 100vh;
    top: 0px;
    left: 0px;
    opacity: 0.9;
}
.quiz-filter-loading{
    display: flex;
    justify-content: center;
    align-items: center;
    height: 300px;
}
.quiz-filter-loading img{
    width: 50px;
    height: auto;
}
.quiz-filter-content{
    display: none;
    position: absolute;
    top: 50px;
    right: 0px;
    background-color: var(--pearl-white);
    width: 370px;
    border-radius: 8px;
    padding: 15px 20px;
    z-index: 10;
    height: 500px;
    overflow-y: auto;
    box-shadow: var(--global-box-shadow);
}
.quiz-filter-checkbox{
    appearance: none;
    -webkit-appearance: none;
    width: 16px;
    height: 16px;
    background-color: white;
    border-radius: 3px;
    cursor: pointer;
    display: grid;
    place-content: center;
    border: 1.7px solid var(--dark-violet);
}
.quiz-filter-checkbox::before{
    content: "";
    width: 6px;
    height: 10px;
    transform: scale(0) rotate(45deg);
    transition: transform 0.2s;
    border-right: 2px solid white;
    border-bottom: 2px solid white;
    margin-bottom: 3px;
    width: 6px;
    height: 12px;
    border-right: 2.4px solid white;
    border-bottom: 2.4px solid white;
}
.quiz-filter-checkbox:checked{
    background-color: var(--dark-violet);
}
.quiz-filter-checkbox:checked::before{
    transform: scale(1) rotate(35deg);
}
.quiz-filter-topic-list-enable{
    display: block;
}
.quiz-filter-topic-list-disable{
    display: none;
}
.quiz-filter-body-enable{
    opacity: 1;
    pointer-events: auto;
}
.quiz-filter-body-disable{
    opacity: 0.5;
    pointer-events: none;
}
.quiz-filter-header{
    margin-bottom: 20px;
}
.quiz-filter-action-btns{
    display: flex;
    justify-content: flex-end;
    column-gap: 20px;
    margin-bottom: 20px;
}
.quiz-filter-title{
    font-family: var(--sub-title-font) !important;
    font-size: 18px;
    font-weight: 500;
    text-align: left;
}
.resource-item{
    display: flex;
    align-items: center;
    margin-top: 10px;
    margin-bottom: 5px;
    box-shadow: var(--global-box-shadow);
    padding: 5px 8px;
    border-radius: 5px;
    background-color: #fff;
    position: relative;
}
.resource-item label{
    display: flex;
    align-items: center;
    cursor: pointer;
    width: 100%;
}
.resource-item label:hover .global-tooltip{
    display: inline;
    bottom: 39px;
    left: 50%;
    transform: translate(-50%, 0%);
    min-width: 250px;
}
.resource-item span{
    font-family: var(--content-font) !important;
    font-size: 16px;
    font-weight: 500;
}
.resource-item input{
    margin-right: 5px;
}
.resource-item img{
    height: 10px;
    width: auto;
    padding: 0px 5px;
    cursor: pointer;
}
.concept-item{
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    margin-left: 15px;
    margin-bottom: 5px;
    position: relative;
}
.concept-item p{
    font-family: var(--content-font) !important;
    font-size: 14px;
    font-weight: 500;
}
.concept-item span{
    font-family: var(--light-font) !important;
    font-size: 13px;
    font-weight: 400;
    text-align: left;
    width: 95px;
}
.concept-item img{
    height: 18px;
    width: auto;
    margin-right: 5px;
    position: absolute;
    left: -20px;
    top: 0px;
}
.concept-item label{
    display: flex;
    align-items: center;
    cursor: pointer;
}
.concept-item label:hover .global-tooltip{
    display: inline;
    bottom: 25px;
    left: 50%;
    transform: translate(-50%, 0%);
    min-width: 250px;
}
.concept-item input{
    margin-right: 5px;
}
.concept-item span{
    margin-left: auto;
}
.quiz-status-container{
    background-color: #fff;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-right: 10px;
    border-radius: 3px;
    cursor: pointer;
    position: relative;
}
.quiz-status-title{
    padding: 5px 15px;
    font-family: var(--sub-title-font) !important;
    font-size: 15px;
    font-weight: 600;
    letter-spacing: 0px;
    cursor: pointer;
    position: relative;
}
.quiz-status-title > img{
    width: auto;
    height: 10px;
    position: absolute;
    top: 6px;
    right: 3px;
    cursor: pointer;
}
.quiz-status-content{
    display: none;
    position: absolute;
    top: 40px;
    right: 0px;
    background-color: #fff;
    width: 330px;
    border-radius: 8px;
    padding: 15px 20px;
    z-index: 10;
    min-height: 60px;
}
.quiz-status-content span{
    font-family: var(--content-font) !important;
    font-size: 14px;
    font-weight: 400;
}

.quiz-thumbs-down{
    background-color: #fff;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 14px 17px;
    border-radius: 3px;
    cursor: pointer;
    box-shadow: var(--global-box-shadow);
    background-color: var(--light-blue);
    border-radius: 5px;
}
.quiz-thumbs-down > img{
    width: auto;
    height: 16px;
}
.next-quiz-clickable-backdrop{
    background-color: rgb(255 255 255 / 0%);
    width: 100vw;
    min-height: 100vh;
    top: 0px;
    left: 0px;
    position: fixed;
    z-index: 1;
    // cursor: pointer;
}
.pdf-viewer-backdrop{
    background-color: #f0f5ff83;
    width: 100vw;
    min-height: 100vh;
    top: 0px;
    left: 0px;
    position: fixed;
    z-index: 11;
    // cursor: pointer;
}

.quiz-question-info{
    display: flex;
    justify-content: center;
    align-items: center;
    column-gap: 8px;
}
.quiz-question-info-text{
    font-family: var(--light-font) !important;
    font-size: 18px;
    font-weight: 400;
    position: relative;
}
.quiz-question-info-text:hover .global-tooltip{
    display: inline;
    bottom: 30px;
    width: 95px;
    left: 50%;
    transform: translate(-50%);
}
.quiz-question-info-indicator{
    height: 20px;
    width: 20px;
    border-radius: 50%;
    background-color: var(--light-green);
    position: relative;
}
.quiz-question-info-indicator:hover .global-tooltip{
    display: inline;
    top: -20px;
    left: 50%;
    transform: translate(-50%, -50%);
}
.quiz-question-text{
    font-family: var(--sub-title-font) !important;
    font-size: 24px;
    font-weight: 500;
    margin-top: 17px;
}
// shuffle mode btn
.shuffle-btn-container{
    display: flex;
    justify-content: flex-end;
    align-items: center;
    column-gap: 8px;
    cursor: pointer;
    position: relative;
}
.shuffle-btn-container:hover .global-tooltip{
    display: inline;
}
.shuffle-btn-text{
    font-family: var(--content-font) !important;
    font-size: 16px;
    font-weight: 400;
}
.shuffle-switch {
    position: relative;
    display: inline-block;
    min-width: 58px;
    height: 32px;
}
.shuffle-slider {
    position: absolute;
    cursor: pointer;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: #e4e4e4;
    transition: 0.4s;
    border-radius: 34px;
}
.shuffle-slider-circle {
    position: absolute;
    content: "";
    height: 24px;
    width: 24px;
    left: 4px;
    bottom: 4px;
    background-color: white;
    transition: 0.4s;
    border-radius: 50%;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}
// input:checked + .shuffle-slider {
//     background-color: var(--dark-violet);
// }
// input:checked + .shuffle-slider:before {
//     transform: translateX(26px);
// }
// Topic card
.free-user-bar{
    background-color: var(--medium-orange);
    box-shadow: var(--global-box-shadow);
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
}
.free-user-bar p{
    font-family: var(--sub-title-font) !important;
    font-size: 14px;
    font-weight: 500;
    padding: 3px 0px;
    color: #fff;
}
.topic-card{
    position: fixed;
    top: 0px;
    background-color: #fff;
    border-bottom-left-radius: 5px;
    border-bottom-right-radius: 5px;
    box-shadow: var(--global-box-shadow);
    width: 680px;
    left: 50%;
    transform: translateX(-50%);
    z-index: 9;
}
.topic-card-header{
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 13px 15px;
    column-gap: 10px;
}
.topic-card-header-left{
    display: flex;
    flex-direction: column;
}
.topic-card-header-left h3{
    font-family: var(--sub-title-font) !important;
    font-size: 17px;
    font-weight: 500;
    line-height: 20px;
    position: relative;
}
.topic-card-header-left h3:hover .global-tooltip{
    display: inline;
    top: 23px;
    left: 50%;
    transform: translate(-50%);
    min-width: 260px;
}
.topic-card-header-left span{
    font-family: var(--light-font) !important;
    font-size: 14px;
    font-weight: 500;
    position: relative;
}
.topic-card-header-left span:hover .global-tooltip{
    display: inline;
    top: 20px;
    left: 50%;
    transform: translate(-50%);
    min-width: 260px;
}
.topic-card-header-desscription{
    display: flex;
    align-items: center;
    column-gap: 5px;
}
.topic-card-header-right{
    display: flex;
    justify-content: space-between;
    align-items: center;
    column-gap: 10px;
}
.topic-card-progress-bar{
    width: 250px;
    height: 20px;
    display: flex;
    align-items: center;
    gap: 5px;
}
.progress-bar-child{
    height: 100%;
    border-radius: 3px;
    box-shadow: var(--global-box-shadow);
    position: relative;
}
.progress-bar-child:hover .global-tooltip{
    display: inline;
    top: 23px;
    left: 50%;
    transform: translate(-50%);
}
.topic-card-open-icon{
    cursor: pointer;
    width: 30px;
    height: 30px;
    display: inline-flex;
    justify-content: center;
    align-items: center;
    border-radius: 50%;
}
.topic-card-open-icon:hover{
    background-color: var(--pearl-white);
}
.topic-card-open-icon img{
    height: 11px;
    width: auto;
    margin-top: 2px;
}
.topic-card-question-list{
    border-top: 1px solid #84919A;
    padding: 10px;
    padding-bottom: 20px;
    height: 350px;
    overflow-y: auto;
}
.topic-card-question-overlay{
    width: 100vw;
    height: 100vh;
    position: fixed;
    z-index: 8;
    background-color: var(--pearl-white);
    opacity: 0.8;
    top: 0px;
    left: 0px;
    cursor: pointer;
}
.topic-question-header{
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 0px 15px;
}
.topic-question-header-left{
    width: 80%;
}
.topic-question-header-right{
    width: 15%;
    display: flex;
    justify-content: center;
    align-items: center;
}
.topic-question-header h2{
    font-family: var(--sub-title-font) !important;
    font-size: 18px;
    font-weight: 500;
    margin-block-start: 0em;
    margin-block-end: 0em;
    line-height: 43px;
}
.topic-question{
    display: flex;
    justify-content: space-between;
    align-items: center;
    column-gap: 6px;
    border-bottom: 1px solid #E5E9EB;
    padding: 0px 15px;
    cursor: pointer;
}
.topic-question:hover{
    background-color: var(--pearl-white);
}
.topic-question-left{
    width: 80%;
}
.topic-question-right{
    width: 15%;
    display: flex;
    justify-content: center;
    align-items: center;    
}
.topic-question h3{
    font-family: var(--light-font) !important;
    font-size: 16px;
    font-weight: 500;
    line-height: 25px;
    padding: 8px 0px;
}
.topic-question aside{
    height: 20px;
    width: 20px;
    border-radius: 50%;
    background-color: var(--light-green);
    position: relative;
}
.topic-question aside:hover .global-tooltip{
    display: inline;
    top: -20px;
    left: 50%;
    transform: translate(-50%, -50%);
}
.topic-question-loading{
    min-height: 200px;
    display: flex;
    justify-content: center;
    align-items: center;
}
.topic-question-loading img{
    height: 70px;
    width: auto;
}
// chapters topics toogle btn
.chapters-topics-toggle-btn{
    display: inline-flex;
    justify-content: space-between;
    align-items: center;
    column-gap: 5px;
    box-shadow: var(--global-box-shadow);
    background-color: var(--light-blue);
    padding: 5px;
    border-radius: 5px;
    cursor: pointer;
    // width: 142px;
}
.chapters-topics-toggle-btn article{
    font-family: var(--sub-title-font) !important;
    font-size: 16px;
    font-weight: 500;
    padding: 5px;
}
.chapters-topics-toggle-btn-selected{
    background-color: #fff;
    box-shadow: var(--global-box-shadow);
    padding: 5px;
    border-radius: 5px;
}
// quiz filter btn
.quiz-filter-btn{
    display: inline-flex;
    align-items: center;
    column-gap: 9px;
    box-shadow: var(--global-box-shadow);
    background-color: #fff;
    padding: 7px 14px;
    border-radius: 5px;
    cursor: pointer;
    position: relative;
}
.quiz-filter-btn:hover{
    background-color: var(--pearl-white);
}
.quiz-filter-btn svg{
    height: 16px;
    width: auto;
}
.quiz-filter-btn span{
    font-family: var(--sub-title-font) !important;
    font-size: 16px;
    font-weight: 500;
}
// disable
.quiz-filter-btn-disable{
    cursor: default;
}
.quiz-filter-btn-disable svg path{
    stroke: var(--stripe-dark-gray);
}
.quiz-filter-btn-disable span{
    color: var(--stripe-dark-gray);
}
// enable
.quiz-filter-btn-enable{
    cursor: pointer;
}
.quiz-filter-btn-enable svg path{
    stroke: #000;
}
.quiz-filter-btn-enable span{
    color: #000;
}
.quiz-filter-counter-icon{
    background-color: #F18F01;
    position: absolute;
    top: 3px;
    left: 19px;
    padding: 0px 5px;
    border-radius: 20px;
}
.quiz-filter-counter-icon-text{
    color: #fff;
    font-family: var(--sub-title-font) !important;
    font-size: 10px;
    font-weight: 500;
    margin-block-start: 0px;
    margin-block-end: 0px;
    line-height: 13px;
}
.quiz-filter-overlay-btn{
    position: absolute;
    z-index: 10;
    top: 0px;
    right: 0px;
    display: inline-flex;
    align-items: center;
    column-gap: 9px;
    box-shadow: var(--global-box-shadow);
    background-color: #fff;
    padding: 7px 14px;
    border-radius: 5px;
    cursor: pointer;
}
.quiz-filter-overlay-btn svg{
    height: 16px;
    width: auto;
}
.quiz-filter-overlay-btn span{
    font-family: var(--sub-title-font) !important;
    font-size: 16px;
    font-weight: 500;
    color: #000;
}
.new-topic-card{
    background-color: #fff;
    box-shadow: var(--global-box-shadow);
    margin-bottom: 10px;
    border-radius: 5px;
    padding: 10px;
    position: relative;
}
.new-topic-card input{
    cursor: pointer;
}
.new-topic-card label{
    display: flex;
    align-items: center;
    cursor: pointer;
    column-gap: 5px;
}
.new-topic-card h2{
    font-family: var(--content-font) !important;
    font-size: 16px;
    font-weight: 500;
    margin-block-start: 0em;
    margin-block-end: 0em;
    line-height: 15px;
}
.new-topic-card label:hover .global-tooltip{
    display: inline;
    bottom: 39px;
    left: 50%;
    transform: translate(-50%);
    min-width: 260px;
}
.quiz-filter-push-mode-bottom{
    background-color: #fff;
    box-shadow: var(--global-box-shadow);
    margin-bottom: 10px;
    border-radius: 5px;
    padding: 10px;
}
.quiz-filter-push-mode-bottom p{
    font-family: var(--sub-title-font) !important;
    font-size: 14px;
    font-weight: 500;
    text-align: center;
    margin-bottom: 7px;
}
.go-unlimited-btn{
    background-color: var(--medium-orange);
    color: #fff;
    padding: 5px 15px;
    border-radius: 5px;
    cursor: pointer;
    display: flex;
    align-items: center;
    gap: 5px;
    justify-content: center;
}
.go-unlimited-btn:hover{
    background-color: var(--dark-orange);
}
.go-unlimited-btn img{
    width: auto;
    height: 18px;
}
.go-unlimited-btn p{
    margin: 0px;
    font-family: var(--content-font) !important;
    font-size: 16px;
    font-weight: 400;
    color: #fff;
}
@media screen and (max-width: 1300px) {
    .topic-card{
        transform: none;
        left: auto;
        right: 290px;
    }
}
@media screen and (max-width: 1080px) {
    .topic-card{
        width: 600px;
    }
    // .horizontal-bar{
    //     display: none;
    // }
    // .topic-card-header-desscription{
    //     flex-direction: column;
    //     align-items: flex-start;
    // }
}
@media screen and (max-width: 1000px) {
    .topic-card{
        right: auto;
        left: 50%;
        transform: translateX(-50%);
        width: 100vw;
    }
    // .horizontal-bar{
    //     display: inline;
    // }
    .topic-card-header-desscription{
        flex-direction: row;
        align-items: center;
    }
    .topic-card-header{
        flex-direction: column;
    }
    .topic-question-header-left{
        width: 80%;
    }
    .topic-card-header-left{
        width: 80%;
        align-items: center;
    }
    .topic-card-header-left h3{
        text-align: center;
    }
    .topic-card-header-right{
        width: 100%;
        column-gap: 15px;
        padding: 0px 5px;
    }
    .topic-card-progress-bar{
        width: 100%;
    }
    .topic-card-open-icon{
        width: 30px;
    }
    .topic-card-header{
        padding: 8px;
        column-gap: 5px;
        row-gap: 5px;
    }
    .quiz-filter-content{
        position: absolute;
        top: 45px;
        width: 95vw;
        height: 90vh;
        margin-left: auto;
        margin-right: auto;
        right: none;
    }
    .quiz-top-bar-container{
        top: 6px;
        right: 6px;
    }
    .quiz-filter-btn{
        padding: 7px 8px;
    }
    .quiz-filter-btn svg{
        height: 18px;
    }
}
@media screen and (max-width: 500px) {
    .shuffle-btn-container{
        column-gap: 4px;
    }
    .chapters-topics-toggle-btn article{
        font-size: 14px;
    }
    .quiz-filter-action-btns{
        column-gap: 15px;
    }
    .topic-card-question-list{
        height: 400px;
    }
    .topic-card-header-left h3{
        font-size: 14px;
    }
    .topic-card-header-left span{
        font-size: 14px;
    }
    .topic-card-header-right{
        margin-top: 10px;
    }
    .topic-card-progress-bar{
        height: 17px;
    }    
    .topic-card-open-icon{
        padding: 0px 5px;
    }

    .filter-content{
        top: -49px;
        right: 10px;
    }
    .question-filter-content{
        top: 55px;
        position: fixed;
        width: 100vw;
        height: 100vh;
    }
    .quiz-question-text{
        font-size: 22px;
    }
    .topic-question{
        column-gap: 5px;
    }
    .topic-question-left{
        width: 80%;
    }    
    .topic-question h3{
        font-size: 16px;
        line-height: 25px;

    }
    .question-counter-content > p {
        font-size: 14px ;
    }
    .question-counter-content > p > span{
        font-size: 14px;
    }

    .quiz-status-title{
        font-size: 14px;
        padding: 3px 13px;
    }
    .quiz-thumbs-down{
        padding: 6px 8px;
    }
    .quiz-thumbs-down > img{
        height: 13px;
    }
    .quiz-status-content{
        // right: -30px;
        top: 40px;
        width: 250px;
    }
}
@media screen and (max-width: 400px) {
    .question-counter-content > p{
        font-size: 12px;
    }
    .question-counter-content > p > span{
        font-size: 12px;
    }
}