body{
  height: 110vh;
  overflow-y: scroll;
}
.global-tooltip{
  font-family: var(--content-font);
  font-weight: 400;
  font-size: 14px;
  background-color: #252C32;
  color: #fff;
  width: fit-content;
  padding: 5px;
  border-radius: 3px;
  position: absolute;
  z-index: 1;
  display: none;
  text-align: center;
}
.concept-container {
  display: flex;
  justify-content: center;
}
.concept-wrapper {
  width: 1000px;
  padding-bottom: 50px;
  margin-top: 0px;
}
.title-section{
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.title-section-right{
  display: flex;
  align-items: center;
  column-gap: 10px;
}
// .title-section-right > div {
//   margin-left: 15px;
// }
// chapter topics btn
// chapters topics toogle btn
.chapters-topics-btn-container{
  position: relative;
}
.chapters-topics-desktop-btn{
  display: flex;
  justify-content: space-between;
  align-items: center;
  column-gap: 5px;
  box-shadow: var(--global-box-shadow);
  background-color: var(--pearl-white);
  padding: 5px;
  border-radius: 5px;
  cursor: pointer;
  width: 152px;
}
.chapters-topics-desktop-btn article{
  font-family: var(--sub-title-font) !important;
  font-size: 16px;
  font-weight: 500;
  border-radius: 5px;
  padding: 6px;
}
.chapters-topics-desktop-btn article:hover{
  box-shadow: var(--global-box-shadow);
}
.chapters-topics-desktop-btn-selected{
  background-color: #fff;
  box-shadow: var(--global-box-shadow);
}
.chapters-topics-mobile-btn{
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #fff;
  padding: 8px;
  width: 40px;
  box-shadow: var(--global-box-shadow);
  border-radius: 5px;
  cursor: pointer;
}
.chapters-topics-mobile-btn svg{
  height: 20px;
  width: auto;
}
// go unlimited btn
.chapters-go-unlimited-btn{
  width: 100%;
  display: flex;
  justify-content: flex-end;
}
.go-unlimited-btn{
  background-color: var(--medium-orange);
  color: #fff;
  padding: 5px 15px;
  border-radius: 5px;
  cursor: pointer;
  display: inline-flex;
  align-items: center;
  gap: 5px;
  justify-content: center;
}
.go-unlimited-btn:hover{
  background-color: var(--dark-orange);
}
.go-unlimited-btn img{
  width: auto;
  height: 20px;
}
.go-unlimited-btn p{
  margin: 0px;
  font-family: var(--content-font);
  font-size: 18px;
  font-weight: 400;
  color: #fff;
}
// loading gif
.loading-gif-box{
  display: flex;
  align-items: center;
  gap: 5px;
}
.loading-gif-box img{
  width: 35px;
  height: auto;
}
.loading-gif-box p{
  font-family: var(--content-font);
  font-size: 16px;
  font-weight: 400px;
  color: var(--dark-orange);
}
// Search box
.search-container{
  display: flex;
  align-items: center;
  gap: 5px;
  box-shadow: var(--global-box-shadow);
  border-radius: var(--global-border-radius);
  background-color: #fff;
  padding: 10px 15px;
  cursor: pointer;
  
}
.search-container > svg{
  height: 18px;
  width: auto;
}
.search-input-wrapper input{
  border: 0px;
  width: 150px;
  outline: none;
  font-family: var(--content-font);
  font-size: 18px;
  font-weight: 400;
  color: #000;
}
.search-container svg path{
  fill: var(--stripe-dark-gray);
}
.search-container input::placeholder{
  color: var(--stripe-dark-gray);
}
.search-container:hover{
  outline: 1px solid #000;
}
.search-container:hover input::placeholder{
  color: #000;
}
.search-container input:focus::placeholder{
  color: transparent;
}
.search-container:hover svg path{
  fill: #000;
}
.examspace-folder{
  background-color: #fff;
  border-radius: 5px;
  padding: 10px 15px;
  display: flex;
  align-items: center;
  column-gap: 8px;
  box-shadow: var(--global-box-shadow);
  cursor: pointer;
}
.examspace-folder p{
  font-family: var(--content-font);
  font-size: 18px;
  font-weight: 400px;
  color: var(--stripe-dark-gray);
}
.examspace-folder svg{
  height: 16px;
  width: auto;
}
.examspace-folder svg path{
  fill: var(--stripe-dark-gray);
}
.examspace-folder:hover{
  outline: 1px solid #000;
}
.examspace-folder:hover svg path{
  fill: #000;
}
.examspace-folder:hover p{
  color: #000;
}
// progress bar
.progress-bar{
  width: 250px;
  height: 20px;
  display: flex;
  align-items: center;
  gap: 5px;
}
.progress-bar-child{
  height: 100%;
  border-radius: 3px;
  box-shadow: var(--global-box-shadow);
  position: relative;
}
.progress-bar-child:hover .global-tooltip{
  display: inline;
  bottom: 24px;
  left: 50%;
  transform: translate(-50%);
}
.resource-list{
  margin-top: 25px;
}
// resource card
.resource-card{
  background-color: #fff;
  border-radius: 5px;
  box-shadow: var(--global-box-shadow);
  margin-bottom: 35px;
}
.resource-card-header{
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 10px 25px;
  gap: 20px;
}
.resource-card-left{
  width: 40%;
}
.resource-card-title{
  font-family: var(--content-font);
  font-size: 19px;
  font-weight: 500;
  letter-spacing: 0px;
  color: var(--dark-black);
  line-height: 26px;
  outline: none;
  cursor: pointer;
  border-radius: 5px;
  padding: 2px 5px;
}
.resource-card-title:hover{
  outline: 1px solid #E5E9EB;
}
.resource-card-title:focus{
  outline: 1px solid #7D8CEB;
}
.resource-card-right{
  display: flex;
  align-items: center;
  justify-content: flex-end;
  gap: 10px;
  width: 62%;
}
.resource-card-right-content{
  display: flex;
  align-items: center;
  justify-content: flex-end;
  gap: 20px;
}
.resource-card-resource-info{
  display: flex;
  align-items: center;
  column-gap: 10px;
  width: 205px;
  justify-content: flex-end;
}
.resource-card-resource-info p{
  margin: 0px;
  font-family: var(--light-font);
  font-size: 16px;
  font-weight: 400;
}
.resource-card-open-handle{
  height: 25px;
  width: 28px;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
}
.resource-card-open-handle svg{
  width: 15px;
  height: auto;
}
.resource-card-open-handle svg path{
  fill: #6D6E78;;
}
.resource-card-open-handle:hover{
  background-color: var(--pearl-white);
}
.resource-card-open-handle:hover svg path{
  fill: #000;
}
.resource-topic-generating{
  width: 100%;
  display: flex;
  justify-content: flex-end;
}
.resource-card-topic-list{
  display: none;
}
.resource-topic-card{
  display: flex;
  align-items: center;
  justify-content: space-between;
  border-top: 1px solid rgb(0 0 0 / 11%);
  padding: 18px 25px;
  gap: 20px;
}
.resource-topic-card-left{
  width: 40%;
}
.resource-topic-card-topic-title{
  width: 100%;
  font-family: var(--content-font);
  font-size: 18px;
  font-weight: 400;
  color: #252C32;
  letter-spacing: 0px;
  line-height: 27px;
  outline: none;
  border: none;
  padding: 2px 5px;
  border-radius: 5px;
  cursor: pointer;
}
.resource-topic-card-topic-title:hover {
  outline: 1px solid #E5E9EB;
}
.resource-topic-card-topic-title:focus {
  outline: 1px solid #7D8CEB;
}
.resource-topic-card-topic-pages{
  font-family: var(--light-font);
  font-size: 15px;
  font-weight: 300;
  color: var(--light-gray);
  padding-left: 5px;
}
.resource-topic-card-right{
  width: 62%;
}
.resource-topic-card-right-content{
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 25px;
}
// .resource-topic-card-mobile-btns{
//   display: none;
// }
.resource-topic-card-btns{
  display: flex;
  align-items: center;
  column-gap: 8px;
}
.topics-ui-card-list{
  margin-top: 25px;
  background-color: #fff;
  box-shadow: var(--global-box-shadow);
  border-radius: 5px;
}
.topics-ui-card{
  display: flex;
  align-items: center;
  justify-content: space-between;
  border-top: 1px solid rgb(0 0 0 / 11%);
  padding: 18px 25px;
  gap: 20px;
}
.topics-ui-card-left{
  width: 40%;
}
.topics-ui-card-title{
  width: 100%;
  font-family: var(--content-font);
  font-size: 18px;
  font-weight: 400;
  color: #252C32;
  letter-spacing: 0px;
  line-height: 27px;
  outline: none;
  border: none;
  padding: 2px 5px;
  border-radius: 5px;
  cursor: pointer;
}
// .topics-ui-card-title:hover{
//   outline: 1px solid #E5E9EB;
// }
// .topics-ui-card-title:focus{
//   outline: 1px solid #7D8CEB;
// }
.topics-ui-card-right{
  width: 62%;
}
.topics-ui-card-right-content{
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 25px;
}
.topics-ui-card-right-polling-gif > img{
  height: 20px;
  width: auto;
}
.topics-ui-card-btns{
  display: flex;
  align-items: center;
  column-gap: 8px;
}
.topics-ui-reactivate-btn{
  width: 100%;
  display: flex;
  justify-content: flex-end;
}
.see-all-questions-btn{
  display: flex;
  align-items: center;
  column-gap: 5px;
  cursor: pointer;
  padding: 7px 10px;
}
.see-all-questions-btn p{
  font-family: var(--light-font);
  font-size: 16px;
  font-weight: 300;
  color: var(--medium-black);
  width: 135px;
}
.see-all-questions-btn svg{
  width: 17px;
  height: auto;
}
.see-all-questions-btn:hover{
  background-color: var(--pearl-white);
  box-shadow: var(--global-box-shadow);
  border-radius: 5px;
}
.practise-btn{
  display: flex;
  align-items: center;
  column-gap: 5px;
  box-shadow: var(--global-box-shadow);
  padding: 7px 10px;
  border-radius: 5px;
  cursor: pointer;
}
.practise-btn p{
  font-family: var(--content-font);
  font-size: 16px;
  font-weight: 400;
  color: var(--dark-black);
}
.practise-btn svg{
  width: 10px;
  height: auto;
}
.practise-btn:hover{
  background-color: var(--pearl-white);
}
.concept-empty-state-container{
  display: flex;
  justify-content: center;
  align-items: center;
  min-height: 500px;
}
.empty-state-content{
  box-shadow: var(--global-box-shadow);
  background-color: #fff;
  padding: 20px;
  width: 500px;
  min-height: 250px;
  border-radius: 20px;
}
.empty-state-upload-file{
  text-align: center;
}
.empty-state-upload-file p{
  font-family: var(--content-font);
  font-size: 22px;
  font-weight: 400;
  margin-top: 25px;
}
.empty-state-upload-file button{
  margin-top: 35px;
  background-color: #f18f01;
  padding: 10px 20px;
  font-family: var(--content-font);
  font-size: 22px;
  font-weight: 600;
  color: #fff;
  border-radius: 20px;
}
.empty-state-upload-file button img{
  width: auto;
  height: 25px;
  margin-right: 15px;
}
.empty-state-generate {
  text-align: center;
}
.empty-state-generate img {
  width: auto;
  height: 60px;
  margin-top: 20px;
}
.empty-state-generate p {
  font-family: var(--content-font);
  font-size: 24px;
  font-weight: 400;
  margin-top: 20px;
}
// empty concepts container
.empty-concepts-loader{
  display: flex;
  justify-content: center;
  margin-top: 100px;
}
.empty-concepts-loader-content{
  width: 500px;
  background-color: #fff;
  box-shadow: var(--global-box-shadow);
  border-radius: 5px;
  padding: 60px 20px;
  min-height: 300px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}
.empty-concepts-loader-content img{
  width: auto;
  height: 75px;
}
.empty-concepts-loader-title{
  font-family: var(--sub-title-font);
  font-weight: 500;
  font-size: 20px;
  margin-top: 20px;
  text-align: center;
}
.empty-concepts-loader-description{
  margin-top: 15px;
  text-align: center;
}
.empty-concepts-loader-description p{
  font-family: var(--light-font);
  font-weight: 300;
  font-size: 18px;
  text-align: center;
}
.empty-concepts-loader-description button{
  margin-top: 35px;
  background-color: var(--medium-orange);
  color: #fff;
  padding: 10px 20px;
  border-radius: 5px;
  font-family: var(--content-font);
  font-size: 18px;
  font-weight: 400;
  cursor: pointer;
  box-shadow: var(--global-box-shadow);
}
// loading icon
.loading-icon{
  height: 50vh;
  display: flex;
  justify-content: center;
  align-items: center;
}
.loading-icon svg{
  height: 90px;
  width: auto;
}
.topics-push-mode-bottom{
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-top: 1px solid #E5E9EB;
  padding: 17px 25px;
}
.topics-push-mode-bottom-left h2{
  font-family: var(--sub-title-font);
  font-size: 18px;
  font-weight: 500;
  color: #000;
  line-height: 24px;
  margin-bottom: 3px;
}
.topics-push-mode-bottom-left p{
  font-family: var(--light-font);
  font-size: 15px;
  font-weight: 400;
  color: #000;
}
.topics-push-mode-top{
  display: flex;
  justify-content: space-between;
  align-items: center;
  background: #fff;
  padding: 17px 25px;
  box-shadow: var(--global-box-shadow);
  border-radius: 5px;
  margin-top: 20px;
}
.topics-push-mode-top-left h2{
  font-family: var(--sub-title-font);
  font-size: 18px;
  font-weight: 500;
  color: #000;
  line-height: 24px;
  margin-bottom: 3px;
}
.topics-push-mode-top-left p{
  font-family: var(--light-font);
  font-size: 15px;
  font-weight: 400;
  color: #000;
}
@media only screen and (max-width: 1450px){
  .resource-card-header{
    gap: 10px;
  }
  .resource-card-resource-info p{
    font-size: 16px;
  }
  .resource-topic-card{
    gap: 10px;
  }
  .topics-ui-card{
    gap: 10px;
  }
  .concept-wrapper{
    width: 80vw;
    margin-left: 190px;
    padding: 0px 10px;
  }
  .resource-card-left{
    width: 35%;
  }
  .resource-topic-card-left{
    width: 35%;
  }
  .topics-ui-card-left{
    width: 35%;
  }
  .progress-bar{
    width: 200px;
  }
  .resource-topic-card-right-content{
    gap: 15px;
  }
  .topics-ui-card-right-content{
    gap: 15px;
  }
  .see-all-questions-btn p{
    font-size: 14px;
  }
  .practise-btn p{
    font-size: 14px;
  }
}
@media only screen and (max-width: 1100px) and (min-width: 1001px){
  .topics-ui-card{
    padding: 18px 10px;
  }
  .topics-ui-card-right{
    width: 65%;
  }
  .see-all-questions-btn p{
    width: 115px;
  }
}

@media only screen and (max-width: 1000px){
  .resource-topic-generating{
    justify-content: flex-start;
  }
  .concept-wrapper{
    width: 95vw;
    margin-left: 0;
    padding: 0px;
  }
  .title-section{
    margin-top: 20px;
  }
  .title-section-right{
    position: fixed;
    z-index: 5;
    top: 0px;
    right: 0px;
    gap: 0px;
    background-color: var(--pearl-white);
    width: 100vw;
    display: flex;
    justify-content: flex-end;
    height: 48px;
    align-items: center;
  }
  .title-section-right > div {
    margin-left: 10px;
  }
  .title-section-heading{
    font-size: 27px;
  }
  .search-container{
    order: -1;
    padding: 8px 10px;
    column-gap: 5px;
  }
  .search-container svg{
    height: 20px;
  }
  .search-container input{
    display: none;
  }
  .resource-list{
    margin-top: 5px;
  }
  .resource-card-header{
    flex-direction: column;
    align-items: flex-start;
    gap: 0px;
    row-gap: 3px;
  }
  .resource-card-left{
    width: 100%;
  }
  .resource-card-score-box{
    width: 100%;
  }
  .resource-card-right{
    width: 100%;
    align-items: flex-end;
  }
  .resource-card-resource-info{
    padding-left: 5px;
    justify-content: flex-start;
  }
  .resource-card-right-content{
    flex-direction: column-reverse;
    align-items: flex-start;
    gap: 15px;
    width: 100%;
  }
  .resource-topic-card{
    flex-direction: column;
    align-items: flex-start;
    padding: 15px 25px;
  }
  .resource-topic-card-left{
    width: 100%;
  }
  .resource-topic-card-right{
    width: 100%;
  }
  .resource-topic-card-btns{
    column-gap: 10px;
  }
  .topics-ui-card{
    flex-direction: column;
    align-items: flex-start;
    padding: 15px 25px;
  }
  .topics-ui-card-left{
    width: 100%;
  }
  .topics-ui-card-right{
    width: 100%;
  }
  .topics-ui-card-btns{
    column-gap: 10px;
  }
  .see-all-questions-btn svg{
    width: 29px;
    height: auto;
  }
  .practise-btn svg{
    height: 27px;
    width: auto;
  }
  .practise-btn{
    box-shadow: none;
  }
  .resource-topic-card-topic-scores{
    width: 80%;
  }
  .topics-ui-card-right-scores{
    width: 80%;
  }
  .progress-bar{
    width: 100%;
  }
  .go-unlimited-btn{
    width: 100%;
    margin-left: 0px;
  }
  .examspace-folder{
    padding: 10px;
  }
  .topics-push-mode-top{
    margin-bottom: 20px;
  }
  .topics-push-mode-top{
    flex-direction: column;
    align-items: flex-start;
    row-gap: 10px;
  }
  .topics-push-mode-top-right{
    width: 100%;
  }
  .topics-push-mode-bottom{
    flex-direction: column;
    align-items: flex-start;
    row-gap: 10px;
  }
  .topics-push-mode-bottom-right{
    width: 100%;
  }
}
@media only screen and (max-width: 500px){
  .resource-card{
    margin-bottom: 25px;
  }
  .search-container input{
    font-size: 14px;
    width: 130px;
    margin-left: 5px;
  }
  .resource-topic-card{
    padding: 15px 15px;
  }
  .topics-ui-card{
    padding: 15px 15px;
  }
  .resource-card-header{
    padding: 15px;
  }
  .practise-btn{
    padding: 4px;
  }
  .see-all-questions-btn{
    padding: 4px;
  }
  .see-all-questions-btn svg{
    width: 25px;
  }
  .practise-btn svg{
    height: 23px;
  }
}