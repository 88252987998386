// common css
.offer-green-bar{
    background-color: #7ED957;
    color: #fff;
    font-family: var(--title-font) !important;
    font-weight: 500;
    font-size: 14px;
    position: absolute;
    top: -22px;
    left: 0px;
    width: 100%;
    border-top-left-radius: 5px;
    border-top-right-radius: 5px;
    text-align: center;
    padding-bottom: 15px;
}
.offer-white-bar{
    background-color: #fff;
    position: absolute;
    top: -1px;
    left: 0px;
    width: 100%;
    height: 20px;
    border-top-left-radius: 10px;
    border-top-right-radius: 10px;
}

.container{
    margin-left: 270px;
    color: #000 !important;
}
.content{
    margin-top: 20px;
}
.sub-cards-container{
    display: flex;
    // align-items: center;
}
// active plan card
.subscription h2{
    font-family: var(--sub-title-font);
    font-size: 30px;
    font-weight: 500;
    margin-bottom: 25px;
    color: #000;
}
.sub-card-1{
    box-shadow: var(--global-box-shadow);
    background-color: #fff;
    border-radius: 5px;
    width: 350px;
    padding: 20px 35px;
    padding-bottom: 40px;
    position: relative;
}
.subscription-sec-1 h3{
    font-family: var(--sub-title-font);
    font-size: 20px;
    font-weight: 500;
    color: #000;
}
.subscription-sec-1 h3 span{
    font-family: var(--sub-title-font);
    font-size: 20px;
    font-weight: 500;
}
.subscription-sec-1 p{
    font-family: var(--content-font);
    font-size: 16px;
    font-weight: 400;
    color: #000;
    margin: 0px;
}
.subscription-sec-2{
    margin-top: 10px;
}
.subscription-sec-2 h3{
    font-family: var(--sub-title-font);
    font-size: 18px;
    font-weight: 500;
    color: #000;
    line-height: 30px;
}
.subscription-sec-2 h3 span{
    font-family: var(--content-font);
    font-size: 18px;
    font-weight: 400;
    color: #000;
}
.subscription-sec-3{
    margin-top: 10px;
}
.subscription-sec-3 button{
    background-color: #F18F01;
    padding: 5px 30px;
    border-radius: 5px;
    color: #fff;
    font-size: 20px;
    font-family: var(--sub-title-font);
    font-weight: 500;
}
.subscription-sec-4{
    margin-top: 20px;
}
.subscription-sec-4 p{
    font-family: var(--sub-title-font);
    font-size: 22px;
    font-weight: 500;
    color: #F18F01;
    cursor: pointer;
}
// secondary plan card
.sub-card-2{
    box-shadow: var(--global-box-shadow);
    width: 350px;
    background-color: #fff;
    min-height: 200px;
    margin-left: 15px;
    border-radius: 5px;
    padding: 20px 35px;
    position: relative;
}

// one month subscription text style
.one-month-sub{
    color: #000;
}
.one-month-sub-txt1{
    font-family: var(--sub-title-font);
    font-weight: 500;
    font-size: 20px;
    color: #000;
    margin-bottom: 0px;
}
.one-month-sub-txt2{
    font-family: var(--title-font);
    font-weight: 700;
    font-size: 28px;
    color: #000;
    display: flex;
    align-items: center;
}
.one-month-sub-txt2 p{
    font-family: var(--light-font);
    font-weight: 400;
    font-size: 22px;
    color: #000;
}
.one-month-sub-txt2 span{
    font-family: var(--title-font);
    font-weight: 700;
    font-size: 14px;
    color: #fff;
    background: linear-gradient(0.25turn, #FF3131, #FF914D);
    padding: 0px 5px;
    border-radius: 30px;
    margin-left: 5px;
    display: flex;
    align-items: center;
    height: 20px;
}
.one-month-sub-txt3{
    font-family: var(--sub-title-font);
    font-weight: 500;
    font-size: 16px;
    color: #000;
    margin-top: 0px;
}
.one-month-sub button{
    font-family: var(--sub-title-font);
    font-weight: 500;
    font-size: 20px;
    background-color: #000;
    color: #fff;
    padding: 5px 30px;
    border-radius: 5px;
    margin-top: 40px;
}

// 3 month sub text
.three-month-sub{
    color: #000;
}
.three-month-sub-txt1{
    font-family: var(--sub-title-font);
    font-weight: 500;
    font-size: 20px;
    margin: 0px;
}
.three-month-sub-txt1 span{
    font-family: var(--sub-title-font);
    font-weight: 500;
    font-size: 20px;
    color: #7ED957;
}
.three-month-sub-txt2{
    font-family: var(--title-font);
    font-weight: 700;
    font-size: 28px;
    display: flex;
    align-items: center;
}
.three-month-sub-txt2 p{
    font-family: var(--light-font);
    font-weight: 400;
    font-size: 22px;
}
.three-month-sub-txt2 span{
    font-family: var(--title-font);
    font-weight: 700;
    font-size: 14px;
    background-color: #7ED957;
    color: #fff;
    padding: 0px 5px;
    border-radius: 30px;
    margin-left: 5px;
    display: flex;
    align-items: center;
    height: 20px;
}
.three-month-sub-txt3{
    font-family: var(--light-font);
    font-weight: 400;
    font-size: 16px;
    margin: 0px;
}
.three-month-sub button{
    font-family: var(--sub-title-font);
    font-weight: 500;
    font-size: 20px;
    background-color: #7ED957;
    color: #fff;
    padding: 5px 50px;
    border-radius: 5px;
    margin-top: 39px;
}


// bill history
.bill-history{
    margin-top: 40px;
}
.bill-history-header{
    display: flex;
    align-items: center;
    cursor: pointer;
}
.bill-history-arrow{
    height: 25px;
    width: auto;
}
.bill-history-header h2{
    font-family: var(--sub-title-font);
    font-size: 30px;
    font-weight: 500;
    margin-bottom: 20px;
    color: #000;
    margin: 0px;
    margin-left: 5px;
}
.bill-history-body{
    margin-top: 10px;
    background-color: #fff;
    padding: 20px 30px;
    border-radius: 5px;
    display: inline-block;
}
.table-header{
    display: flex;
    align-items: center;
}
.table-header h3{
    font-family: var(--sub-title-font);
    font-size: 20px;
    font-weight: 500;
    color: #000;
}
.table-header-col1{
    min-width: 140px;
}
.table-header-col2{
    min-width: 120px;
}
.table-header-col3{
    min-width: 130px;
}
.table-row{
    display: flex;
    align-items: center;
    margin-bottom: 10px;
}
.table-content p{
    color: #000;
    font-family: var(--content-font);
    font-weight: 400;
    font-size: 16px;
    margin: 0px;
}
.table-content button{
    background-color: #F0F5FF;
    color: #000;
    padding: 3px 30px;
    font-family: var(--sub-title-font);
    font-size: 16px;
    font-weight: 500;
    border-radius: 5px;
    min-width: 98px;
}
.bill-history-footer{
    margin-top: 30px;
}
.bill-history-footer p{
    color: #000;
    font-family: var(--content-font);
    font-weight: 400;
    font-size: 16px;
}
// profile
.profile{
    margin-top: 40px;
    padding-bottom: 100px;
}
.profile h2{
    font-family: var(--sub-title-font);
    font-size: 30px;
    font-weight: 500;
    margin-bottom: 20px;
    color: #000;
}
.profile-inputs{
    margin-top: 20px;
    width: 350px;
}
.password-input{
    pointer-events: none;
}
.profile-inputs input{
    box-shadow: var(--global-box-shadow);
    background-color: #fff;
    padding: 15px 20px;
    font-size: 18px;
    color: #000;
    border-radius: 30px;
    border: none;
    display: block;
    width: 100%;
    margin-bottom: 20px;

}
.profile-inputs input:focus{
    outline: none;
}
.profile-btn button{
    background-color: #F18F01;
    padding: 5px 30px;
    border-radius: 5px;
    color: #fff;
    font-size: 20px;
    font-family: var(--sub-title-font);
    font-weight: 500;
    margin-top: 10px;
}

// popup

.manage-popup-backdrop{
    position: fixed;
    top: 0px;
    left: 0px;
    width: 100vw;
    height: 100vh;
    background-color: #f0f5ffb9;
    // display: flex;
    // justify-content: center;
    // margin-top: 100px;
    // align-items: center;
}

.manage-popup-content{
    background-color: #fff;
    width: 460px;
    border-radius: 5px;
    padding: 30px;
    min-height: 370px;
    margin: 100px auto 0px auto;
    padding-bottom: 40px;
    display: flex;
    justify-content: center;
    align-items: center;
    box-shadow: var(--global-box-shadow);
}
.manage-popup-cancel-sec1{
    font-family: var(--sub-title-font);
    font-size: 25px;
    font-weight: 500;
    margin-bottom: 20px;
    color: #000;
    text-align: center;
}
.manage-popup-cancel-sec2{
    font-family: var(--sub-title-font);
    font-size: 16px;
    font-weight: 500;
    margin-bottom: 20px;
    color: #000;
    text-align: center;
}
.manage-popup-cancel-sec3{
    font-family: var(--content-font);
    font-size: 16px;
    font-weight: 400;
    margin-bottom: 20px;
    color: #000;
    text-align: center;
}
.manage-popup-cancel-sec4{
    text-align: center;
}
.manage-popup-cancel-sec4 button{
    background-color: #F18F01;
    padding: 5px 40px;
    border-radius: 5px;
    color: #fff;
    font-size: 20px;
    font-family: var(--sub-title-font);
    font-weight: 500;
    margin-top: 10px;
}
.manage-popup-cancel-sec4 p{
    font-family: var(--sub-title-font);
    font-size: 16px;
    font-weight: 500;
    margin-top: 10px;
    color: #000;
    text-align: center;
    cursor: pointer;
}
// popup loading content
.manage-popup-loading{
    display: flex;
    justify-content: center;
    align-items: center;
    height: 290px;
}
.manage-popup-loading > img{
    height: 90px;
    width: auto;
}
// popup done content
.manage-popup-success-sec1{
    text-align: center;
}
.manage-popup-success-sec1 img{
    height: 40px;
    width: auto;
}
.manage-popup-success-sec2{
    margin-top: 10px;
}
.manage-popup-success-sec2 h2{
    text-align: center;
    font-family: var(--sub-title-font);
    font-size: 26px;
    font-weight: 500;
    margin-bottom: 25px;
    color: #000;
    line-height: 35px;
}
.manage-popup-success-sec3{
    margin-top: 20px;
}
.manage-popup-success-sec3 p{
    font-family: var(--content-font);
    font-size: 16px;
    font-weight: 400;
    margin-bottom: 20px;
    color: #000;
    text-align: center;
    padding: 0px 50px;
}
.manage-popup-success-sec4{
    margin-top: 45px;
    text-align: center;
}
.manage-popup-success-sec4 button{
    background-color: #F18F01;
    padding: 5px 70px;
    border-radius: 5px;
    color: #fff;
    font-size: 20px;
    font-family: var(--sub-title-font);
    font-weight: 500;
    margin-top: 10px;
}
// popup downgrade success
.downgrade-plan-sec1{
    text-align: center;
}
.downgrade-plan-sec1 img{
    height: 40px;
    width: auto;
}
.downgrade-plan-sec2{
    margin-top: 10px;
}
.downgrade-plan-sec2 h2{
    text-align: center;
    font-family: var(--sub-title-font);
    font-size: 26px;
    font-weight: 500;
    margin-bottom: 25px;
    color: #000;
    line-height: 35px;
}
.downgrade-plan-sec3{
    margin-top: 20px;
}
.downgrade-plan-sec3 p{
    font-family: var(--content-font);
    font-size: 16px;
    font-weight: 400;
    margin-bottom: 20px;
    color: #000;
    text-align: center;
    padding: 0px 50px;
}
.downgrade-plan-sec4{
    margin-top: 45px;
    text-align: center;
}
.downgrade-plan-sec4 button{
    background-color: #F18F01;
    padding: 5px 70px;
    border-radius: 5px;
    color: #fff;
    font-size: 20px;
    font-family: var(--sub-title-font);
    font-weight: 500;
    margin-top: 10px;
}
.upgrade-plan{
    height: 100%;
}
.upgrade-plan-done-text1{
    text-align: center;
    font-family: var(--content-font);
    font-size: 16px;
    font-weight: 400;
    margin-top: 15px;
    padding: 0px 75px;
}

@media only screen and (max-width: 1000px) and (min-width: 500px){
    .container{
        margin-left: 74px;
    }
}
@media screen and (max-width: 800px){
    .sub-cards-container{
        flex-direction: column;
        align-items: flex-start;
    }
    .sub-card-2{
        margin-left: 0px;
        margin-top: 40px;
    }
}
@media screen and (max-width: 500px){
    .container{
        margin-left: 0px;
    }
    .content{
        width: 85vw;
        margin-left: auto;
        margin-right: auto;
    }
    .subscription-sec-3 button{
        font-size: 18px;
    }
    .sub-card-1, .sub-card-2{
        width: 100%;
    }
    .one-month-sub button{
        font-size: 18px;
    }

    .bill-history-body{
        width: 100%;
    }
    .table-content button{
        padding: 3px 19px;
        min-width: 70px;
    }
    .profile-inputs{
        width: 100%;
    }
    .table-header-col1{
        min-width: 110px;
    }
    .table-header-col2{
        min-width: 75px;
    }
    .table-header-col3{
        min-width: 65px;
    }
    .manage-popup-content{
        width: 85vw;
        min-height: 350px;
    }
    .manage-popup-cancel-sec1{
        font-size: 24px;
    }
    .manage-popup-cancel-sec2,.manage-popup-cancel-sec3{
        font-size: 15px;
    }
    .manage-popup-success-sec1 img{
        height: 35px;
    }
    .manage-popup-success-sec2 h2{
        font-size: 24px;
    }
    .manage-popup-success-sec3 p{
        padding: 0px;
    }
    .subscription-sec-4 p{
        font-size: 19px;
    }
    .subscription h2, .bill-history-header h2, .profile h2{
        font-size: 22px;
    }
    .bill-history-arrow{
        height: 20px;
    }
    .bill-history{
        margin-top: 30px;
    }
    .profile{
        margin-top: 20px;
    }
    .manage-popup-success-sec4{
        margin-top: 21px;
    }
    .manage-popup-cancel-sec1{
        font-size: 21px;
    }
    .downgrade-plan-sec3 p{
        padding: 0px 10px;
    }
    .upgrade-plan-done-text1{
        padding: 0px 0px;
    }
}

@media screen and (max-width: 400px){
    .manage-popup-cancel-sec1{
        font-size: 19px;
    }
    .manage-popup-success-sec4 button{
        padding: 5px 65px;
        font-size: 18px;
    }
    .offer-green-bar{
        font-size: 13px;
    }
}


// .title{
//     font-size: 35px;
//     font-family: var(--sub-title-font) !important;
//     font-weight: 500;
//     letter-spacing: 0px;
// }
// .input-box-container{
//     margin-top: 70px;
// }
// .input-fields{
//     margin-top: 25px;
//     position: relative;
// }
// .input-fields > input{
//     display: block;
//     width: 100%;
//     padding: 10px 20px;
//     border: 0px;
//     border-radius: 50px;
//     font-size: 17px;
//     background-color: #c3c6cc;
//     pointer-events: none;
// }
// .input-fields > input:focus{
//     outline: none;
// }
// .input-fields > p{
//     margin-top: 15px;
//     font-size: 16px;
//     cursor: pointer;
// }
// .input-fields > aside{
//     position: absolute;
//     top: 12px;
//     right: 20px;
// }
// .input-fields > aside > svg{
//     height: auto;
//     width: 20px;
// }
// .save-btn{
//     background-color: #F18F01;
//     font-size: 18px;
//     padding: 8px 25px;
//     border-radius: 50px;
//     margin-top: 20px;
//     color: #fff;
//     font-weight: 600;
//     font-family: var(--sub-title-font) !important;
//     font-weight: 600;
//     letter-spacing: 0.5px;
// }