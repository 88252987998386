.exam-space-popup-container {
    color: #000;
    position: fixed;
    top: 0px;
    left: 0px;
    display: none;
    z-index: 10;
}
.exam-space-popup-backdrop {
    position: fixed;
    background-color: #f7fafcf3;
    width: 100vw;
    height: 100vh;
}

.exam-space-heading {
    display: flex;
    align-items: center;
    gap: 20px;
}

.exam-space-heading img {
    width: 35px;
    height: 35px;
    cursor: pointer;
}
.outline-container-outer{
    width: 80vw;
    margin-left: 200px;
}
.outline-container-inner{
    width: 1000px;
    margin-left: auto;
    margin-right: auto;
    display: flex;
    flex-direction: column;
    height: 100vh;
    justify-content: center;
}

.exam-space-popup-content {
    z-index: 2;
    background-color: #fff;
    height: 500px;
    border-radius: 10px;
    position: relative;
    box-shadow: var(--global-box-shadow);
    padding: 25px;
}

.close-icon {
    position: absolute;
    top: 20px;
    right: 20px;
    cursor: pointer;
    height: 18px;
    width: auto;
}

.exam-space-popup-title {
    font-size: 25px;
    font-weight: 500;
    color: #000;
    font-family: var(--sub-title-font);
}

.exam-space-organize-text {
    font-family: var(--light-font);
    font-weight: 300;
    color: #000;
    margin-bottom: 10px;
}

.exam-space-folder {
    cursor: pointer;
    position: relative;
    /* border: 1px solid black; */
    border-radius: 10px;
    padding: 10px;
    /* width: ; */
}

.exam-space-folder p {
    margin-top: 0px;
}

.folder-name {
    font-size: 18px;
    font-family: var(--content-font);
    font-weight: 400;
    line-height: 21px;
}

.files-count,
.ques-count {
    font-size: 14px;
    font-family: var(--light-font);
    color: #000;
}
.exam-space-folders-wrapper{
    height: 350px;
    overflow-y: auto;
    padding: 5px 10px;
}
.exam-space-folders-container {
    display: grid;
    grid-template-columns: repeat(4, 1fr);
    grid-gap: 15px;
    margin-top: 20px;
}

.arrow-down-icon {
    font-size: 40px;
}


/* Import Popup  */
.import-popup-container {
    color: #000;
    position: absolute;
    top: 0px;
}

.import-popup-backdrop {
    background-color: #f7fafce0;
    width: 100vw;
    height: 100vh;
    display: flex;
    justify-content: center;
    align-items: center;
}

.import-popup-content {
    z-index: 2;
    background-color: #fff;
    border-radius: 10px;
    position: relative;
    box-shadow: var(--global-box-shadow);
    padding: 55px 25px 25px 25px;
}
.import-popup-content-wrapper{
    padding: 0px 10px;
    overflow-y: auto;
    height: 500px;
}
.import-popup-section {
    margin-bottom: 40px;
}

.import-popup-section p {
    color: #000;
}

.import-heading {
    display: flex;
    align-items: center;
    cursor: pointer;
}

.import-popup-title {
    font-size: 24px;
}

.import-file-icon {
    cursor: pointer;
    width: 20px;
    height: 20px;
    opacity: 0.6;
}

.import-file-tick-icon {
    cursor: pointer;
    width: 20px;
    height: 20px;
}

.import-file-content {
    margin-left: 35px;
    display: flex;
    gap: 10px;
    align-items: center;
    margin-top: 20px;
}

.import-file-content p {
    font-size: 16px;
}

.trash-icon {
    fill: white;
    font-size: 15px;
    padding: 3px;
    background-color: #D9D9D9;
    height: 20px;
    width: 20px;
    border-radius: 50%;
    cursor: pointer;
}

.trash-icon-folder {
    position: absolute;
    fill: white;
    bottom: 5px;
    right: 5px;
    font-size: 15px;
    padding: 3px;
    background-color: #27345E;
    height: 20px;
    width: 20px;
    border-radius: 50%;
    cursor: pointer;
}

.exam-space-folder-hover-content {
    position: absolute;
    background-color: #27345E;
    border: 1px solid #27345E;
    border-radius: 10px;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    padding: 10px 10px;
    text-align: center;
}

.exam-space-folder-hover-content p {
    font-size: 13px;
    text-align: left;

}
.exam-space-folder-loading-gif{
    height: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    cursor: default;
}
.exam-space-folder-loading-gif > img{
    height: 30px;
    width: auto;
}
.exam-folder-btn {
    width: fit-content;
    display: flex;
    gap: 10px;
    align-items: center;
    margin: 5px;
}

.exam-folder-delete {
    background-color: white;
    padding: 2px 20px;
    border-radius: 5px;
}

.exam-folder-keepit {
    background: none;
    color: white;

}

.exam-space-add-mobile {
    display: none;
}

.file-count-text {
    font-family: var(--light-font);
    font-size: 16px;
    margin-left: 40px;
    margin-top: 0px;
}
.examspace-info-container{
    display: flex;
    justify-content: flex-end;
    margin-bottom: 10px;
}
.examspace-info-label{
    background-color: #fff;
    padding: 10px 15px;
    border-radius: 5px;
    display: flex;
    align-items: center;
    justify-content: center;
    box-shadow: var(--global-box-shadow);
    column-gap: 8px;
}
.examspace-info-label p{
    font-family: var(--content-font);
    font-size: 18px;
    font-weight: 500;
}
.examspace-info-label svg{
    height: 18px;
    width: auto;
}
@media screen and (max-width: 1300px) {
    .outline-container-inner{
        width: 90%;
    }
}
@media screen and (max-width: 1000px) {
    .outline-container-outer{
        width: 100vw;
        margin-left: 0px;
        padding: 0px 20px;
    }
    .outline-container-inner{
        width: 100%;
        justify-content: flex-start;
        margin-top: 80px;
    }
}
@media screen and (max-width: 800px) {
    .exam-space-folders-wrapper{
        margin-top: 20px;
    }
    .import-popup-section {
        margin-bottom: 15px;
    }
    .exam-space-popup-content {
        height: 500px;
    }
    .exam-space-folders-container {
        grid-template-columns: repeat(2, 1fr);
    }

    .exam-space-popup-title {
        font-size: 16px;
        line-height: 20px;
    }

    .exam-space-organize-text {
        font-size: 14px;
    }

    .folder-name {
        font-size: 14px;
    }

    .exam-space-folders-container {
        display: grid;
        grid-template-columns: repeat(1, 1fr);
        grid-gap: 20px;
        margin-top: 20px;
    }

    .exam-space-heading img {
        display: none;
    }

    .exam-space-add-mobile {
        display: flex;
        gap: 5px;
        margin-top: 5px;
    }

    .exam-space-add-mobile img {
        width: 20px;
        height: 20px;
    }
    .import-popup-title {
        font-size: 18px;
    }
    .import-popup-content {
        padding: 55px 10px 25px 10px;
    }

    /* .import-popup-title {
        font-size: 12px;
    }
    .file-count-text {
        font-size: 12px;
        margin-left: 18px;
    }

    .arrow-down-icon {
        font-size: 20px;
    }

    .import-file-content p {
        font-size: 12px;
    }

    .import-file-content img {
        width: 15px;
        height: 15px;
    } */
}